<script>
import {Line} from "vue-chartjs";
import {Chart as ChartJS} from 'chart.js'

ChartJS.defaults.global.defaultFontFamily = "Nunito Sans";

export default {
  extends: Line,
  props: ['labels', 'data'],

  mounted() {
    this.renderGraph()
  },
  computed: {
    chartData: function () {
      return this.data;
    }
  },
  watch: {
    data: function () {
      this.renderGraph();
    }
  },
  methods: {

    renderGraph() {
      var data = this.chartData;
      var labels = this.labels;
      this.renderChart(
          {
            labels,
            datasets: data
          },
          {
            height: "100",
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,

            },
            legend: {
              display: true
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    fontSize: "12",


                    fontColor: this.$store.state.dark_theme
                        ? "#718096"
                        : "#777777"


                  },
                  gridLines: {
                    display: false
                  }
                }
              ],
              yAxes: [
                {

                  gridLines: {

                    color: this.$store.state.dark_theme ? "#718096" : "#D8D8D8",
                    zeroLineColor: this.$store.state.dark_theme
                        ? "#718096"
                        : "#D8D8D8",
                    borderDash: [2, 2],
                    zeroLineBorderDash: [2, 2],
                    drawBorder: true

                  }
                }
              ]
            },
          }
      );
    }
  }


};
</script>